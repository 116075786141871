<template>
  <div v-if="obj" class="integration-sendgrid">
    <div class="row">
      <div class="col-md-6">
        <strong>Mail API Key <span class="text-danger">(required)</span></strong>
        <p>
          <i>Pro plan and up!</i>
          Please create a new API Key for your website integration by navigating to the SendGrid UI, and open Settings >
          <span class="text-primary">API Keys</span>.
          To successfully send email using SendGrid with your website integration, please ensure that the API Key has
          "Mail Send" permission assigned to it.
        </p>
        <div class="mt-4">
          <strong>Tracking incoming emails <span class="text-danger">( important )</span></strong>
          <span class="d-block">To track incoming emails for Inbox and Automation, you will need to update your sender DNS as follows:</span>
          <table class="mt-2">
            <tr>
              <th style="width: 80px;">Type</th>
              <th style="width: 80px;">Name</th>
              <th style="width: 120px;">Priority</th>
              <th style="width: 80px;">Value</th>
            </tr>
            <tr>
              <td>MX</td>
              <td>email</td>
              <td>10</td>
              <td>mx.sendgrid.net</td>
            </tr>
          </table>
        </div>
        <div v-if="isEdit" class="row mt-4">
          <div class="col-md-12 d-flex align-items-center">
            <div style="flex: auto">
              <TextInput v-if="!obj.editApiKey" v-model="obj.api_key" name="ApiKey" label="Api Key" :disabled="true"
                rules="required" sublabel="(*)" />
              <TextInput v-else v-model="obj.api_key_new" name="ApiKey" label="Api Key" rules="required"
                sublabel="(*)" />
            </div>
            <span class="btn btn-primary btn-sm ml-2" @click="cancelEditApiKey">{{ obj.editApiKey ? 'Cancel' : 'Change'
              }}</span>
          </div>
          <div class="col-md-12">
            <TextInput v-model="obj.options.from_email" type="email" name="From Email" label="From Email" sublabel="(*)"
              rules="required" />
          </div>
        </div>
        <div v-else class="mt-4">
          <TextInput v-model="obj.api_key" name="ApiKey" label="Api Key" sublabel="(*)" rules="required" />
          <TextInput v-model="obj.options.from_email" type="email" name="From Email" label="From Email" sublabel="(*)"
            rules="required" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
    }
  },

  computed: {
  },

  mounted() {
  },

  methods: {
    cancelEditApiKey() {
      this.obj.editApiKey = !this.obj.editApiKey
    },
  },
}
</script>

<style lang="scss" scoped></style>